/* You can add global styles to this file, and also import other style files */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import '~leaflet/dist/leaflet.css';

.p-datatable.oc-border, .p-treetable.oc-border {
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
}

.validation-error {
    margin-top: 3px;
    margin-left: 3px;
    color: red;
    font-size: 12px;
}

.required:after {
    content: " *";
    color: red;
}

.p-disabled, .p-component:disabled {
    opacity: 0.70;
    background-color: #c0c0c0;
    color: black;
}

.badge {
    display: inline-block;
    background-color: #c2c2c2;
    color: #000;
    line-height: 1;
    text-align: center;
    margin-top: 1px;
    -moz-border-radius: .25rem;
    -webkit-border-radius: .25rem;
    border-radius: .25rem;
    padding: .25em .4em;
    vertical-align: baseline;
    white-space: nowrap;
}

.badge.success {
    background-color: #6ebc3b;
    color: #fff;
}

.badge.danger {
    background-color: #EF5350;
    color: #fff;
}

.badge-small {
    display: inline-block;
    background-color: #c2c2c2;
    color: #000;
    line-height: 1;
    text-align: center;
    margin-top: 0px;
    -moz-border-radius: .25rem;
    -webkit-border-radius: .25rem;
    border-radius: .25rem;
    padding: .15em .4em;
    vertical-align: baseline;
    white-space: nowrap;
}

.badge-small.danger {
    background-color: #EF5350;
    color: #fff;
}


.pac-container {
    z-index: 10000;

}

.pac-logo:after {
    display: none !important;
    content: none !important;
}


::-webkit-scrollbar {
    width: 8px;
    position: fixed;
}

::-webkit-scrollbar-track {
    background: #eee;
}

::-webkit-scrollbar-thumb {
    background-color: #6b8a99;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #6c8a9999;
}


.ql-editor {
    .content {
        img {
            width: 100%;
        }
    }
}

.p-fileupload-row {
    div:first-child {
        display: none;
    }
    div{
        width: auto !important;
        padding: 5px !important;
    }
}
